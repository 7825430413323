import React from 'react'
import styled from 'styled-components';

const Button = styled.button`
  margin: 0 auto;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  width: 60%;
  color: #FFF;
  background: var(--red);
  border: 1px solid var(--red);
  cursor: pointer;
  color: #FFF;
`;
const InputButton = Button.withComponent('input').extend`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SubmitButton = ({text}) => (
  <Button type="submit">{text}</Button>
)

export const DripButton = ({text}) => (
  <InputButton type="submit" value={text} data-drip-attribute="sign-up-button"/>
)

export const LinkButton = ({text, linkTo}) => {
  const handleSearch = function() {
    let path = `${linkTo}`;
    if (path === undefined || path === `undefined` || path === ``) { path = `/`; } 
    window.location = `${path}`;
  };

  return (
    <Button type="submit" onClick={handleSearch}>{text}</Button>
  );
}