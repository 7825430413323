import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import { PageTitle } from '../components/heading';
import { LinkButton } from '../components/buttons';


const ThankYou = styled.div `
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  background: var(--red);

  h1 {
    margin: 0;
  }

  .inner {
    width: 80%;
    max-height: 600px;
    /* min-height: 400px; */
    margin: 0 auto;
    background: #FFF;
    padding: 30px;
    text-align: center;
  }

`;

const SuccessPage = () => (
  <Layout title={'Subscribed'} metaDescription={''}>
    <ThankYou>
      <div className="inner">
        <PageTitle text={'My Apron will be coming soon!'}></PageTitle>
        <br/>
        <p>Thanks for subscribing for updates from My Apron.</p>
        <p>We will be in touch soon to let you know what we're up to and when you can start using this new exciting way of managing your recipes.</p>
        <p>If you have any feedback, questions or just want to chat, send us an email at <a href="mailto:contact@myapron.co">contact@myapron.co</a></p>
        <br/>
        <LinkButton text={`Keep reading about My Apron`}></LinkButton>
      </div>
    </ThankYou>
  </Layout>
)

export default SuccessPage