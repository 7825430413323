import React from 'react'
import styled from 'styled-components';


const Title = styled.h1`
    font-size: 40px;
    color: var(--red);
    &.green {
      color: green;
    }
`;

export const PageTitle = ({text}) => (
  <Title>{text}</Title>
)

export const PageTitleGreen = ({text}) => (
  <Title className={`green`}>{text}</Title>
)

// export PageTitleGreen;
// export default PageTitle
